import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";
import { constructQueryString } from "../../Util/HelperFunction";

export const studentListAction = createAsyncThunk(
    'student/list', async (reqBody, thunkAPI) => {
        try {
            const query = constructQueryString(reqBody)
            const response = await Api.get(`/student/list?${query}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    export const studentCsvListAction = createAsyncThunk(
        'student/Csvlist', async (reqBody, thunkAPI) => {
            try {
                const query = constructQueryString(reqBody)
                const response = await Api.get(`/student/csv/download?${query}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    



export const studentCollectionListAction = createAsyncThunk(
    'student/collection', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/student/collection?id=${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


    export const avatarAction = createAsyncThunk(
        'student/avatar', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/auth/avatar`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

    



    export const studentEditAction = createAsyncThunk(
        'student/edit', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.put('student/edit', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    
    
    

    export const studentCreateAction = createAsyncThunk(
        'student/create', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('student/create', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

    export const weelklyLoginStudentAction = createAsyncThunk(
        'student/studentLogin', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/student/active-hours?id=${reqBody?.id}&startOfWeek=${reqBody?.date.startOfWeek}&endOfWeek=${reqBody?.date?.endOfWeek}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

    export const studentClassListAction = createAsyncThunk(
        'student/class', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/student/class?id=${reqBody.id}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

        export const studentDeleteAction = createAsyncThunk(
            'student/delete', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.delete(`/student/delete?id=${reqBody.id}`)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
            
        

        

        export const studentSuspendAction = createAsyncThunk(
            'student/suspend', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.post(`/student/suspend`,reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
    
        

        
    
export const studentdetailsAction = createAsyncThunk(
    'student/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/student/details?id=${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const studentResetPassAction = createAsyncThunk(
    'student/reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`student/reset-password`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

