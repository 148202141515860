import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";
import { constructQueryString } from "../../Util/HelperFunction";

export const parentListAction = createAsyncThunk(
    'parent/list', async (reqBody, thunkAPI) => {
        try {
            const query = constructQueryString(reqBody)
            const response = await Api.get(`/parent/list?${query}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export const parentCsvListAction = createAsyncThunk(
    'parent/parentCsvlist', async (reqBody, thunkAPI) => {
        try {
            const query = constructQueryString(reqBody)
            const response = await Api.get(`/parent/csv/download?${query}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })




export const parentCollectionListAction = createAsyncThunk(
    'parent/collection', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/parent/collection?id=${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


    export const avatarAction = createAsyncThunk(
        'parent/avatar', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/auth/avatar`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

    



    export const parentEditAction = createAsyncThunk(
        'parent/edit', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.put('parent/edit', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    
    
    

    export const parentCreateAction = createAsyncThunk(
        'parent/create', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('parent/create', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

    export const weelklyLoginparentAction = createAsyncThunk(
        'parent/parentLogin', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/parent/active-hours?id=${reqBody?.id}&startOfWeek=${reqBody?.date.startOfWeek}&endOfWeek=${reqBody?.date?.endOfWeek}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

    export const parentClassListAction = createAsyncThunk(
        'parent/class', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/parent/class?id=${reqBody.id}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

        export const parentDeleteAction = createAsyncThunk(
            'parent/delete', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.delete(`/parent/delete?id=${reqBody.id}`)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
            
        

        

        export const parentSuspendAction = createAsyncThunk(
            'parent/suspend', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.post(`/parent/suspend`,reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
    
        

        
    
export const parentdetailsAction = createAsyncThunk(
    'parent/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/parent/details?id=${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const parentResetPassAction = createAsyncThunk(
    'parent/reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`parent/reset-password`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

