import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";
import { constructQueryString } from "../../Util/HelperFunction";


export const teacherListAction = createAsyncThunk(
    'teacher/list', async (reqBody, thunkAPI) => {
        try {
            const query = constructQueryString(reqBody)
            const response = await Api.get(`teacher/list?${query}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })
    


    export const teacherCsvListAction = createAsyncThunk(
        'teacher/teacherCsvlist', async (reqBody, thunkAPI) => {
            try {
                const query = constructQueryString(reqBody)
                const response = await Api.get(`teacher/csv/download?${query}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
        










    export const teacherDeleteAction = createAsyncThunk(
        'teacher/delete', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.delete(`teacher/delete?id=${reqBody.id}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })



    export const teacherStudentIdListAction = createAsyncThunk(
        'teacher/studentlist', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`teacher/student?teacherId=${reqBody.id}&search=${reqBody?.searchValue}`)
    
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    


    export const teacherCollectionListAction = createAsyncThunk(
        'teacher/collection', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`teacher/collection?id=${reqBody?.id}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })



    export const weelklyLoginTeacherAction = createAsyncThunk(
        'teacher/teacherLogin', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`teacher/active-hours?id=${reqBody?.id}&startOfWeek=${reqBody?.date.startOfWeek}&endOfWeek=${reqBody?.date?.endOfWeek}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })




        

    export const teacherClassListAction = createAsyncThunk(
        'teacher/class', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`teacher/class?id=${reqBody?.id}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

        

export const teacheResetPassAction = createAsyncThunk(
    'teacher/reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`teacher/reset-password`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const teacherDetailAction = createAsyncThunk(
    'teacher/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`teacher/details?id=${reqBody.id}`)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const TeacherCreateAction = createAsyncThunk(
    'teacher/create', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post(`/teacher/create`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



export const teacherSuspendAction = createAsyncThunk(
    'teacher/suspend', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post(`/teacher/suspend`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })
    

export const TeacherEditAction = createAsyncThunk(
    'teacher/edit', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`/teacher/edit`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })
